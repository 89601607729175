import { Chip, Divider, Icon, withStyles } from "@material-ui/core";
import StyleIcon from "@mui/icons-material/Style";
import { Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Handle, Position } from "reactflow";

/*eslint-disable*/
export default function ActionNode(node) {
  /*
    public const int AUTOMATION_START_EVENT_SUBSCRIBER_ADDED = 0;
        public const int AUTOMATION_START_EVENT_FINISHED_SERIE = 1;
        public const int AUTOMATION_START_EVENT_ENGAGED_BROADCAST = 2;
        public const int AUTOMATION_START_EVENT_ENGAGED_CATALOG = 3;
        public const int AUTOMATION_START_EVENT_ENGAGED_ITEM = 4;

        public const string AUTOMATION_STEP_TYPE_INPUT = "AUTOMATION_STEP_TYPE_INPUT";
        public const string AUTOMATION_STEP_TYPE_SEND_EMAIL = "AUTOMATION_STEP_TYPE_SEND_EMAIL";
        public const string AUTOMATION_STEP_TYPE_CALL_WEBHOOK = "AUTOMATION_STEP_TYPE_CALL_WEBHOOK";
        public const string AUTOMATION_STEP_TYPE_WAIT_TIME = "AUTOMATION_STEP_TYPE_WAIT_TIME";
        public const string AUTOMATION_STEP_TYPE_WAIT_EVENT = "AUTOMATION_STEP_TYPE_WAIT_EVENT";
        */

  const StyleChip = withStyles({
    root: {
      backgroundColor: "gray",
      borderColor: "white",
      "& .MuiChip-label": {
        fontSize: 8,
        color: "white",
      },
    },
  })(Chip);

  const onClick = (event) => {
    node.data.onClickAdd(event, node);
  };

  const getIcon = (type) => {
    switch (type) {
      case "AUTOMATION_START_EVENT_TAG_ADDED":
        return (
          <Icon fontSize="small" mt={3}>
            sell
          </Icon>
        );
      case "AUTOMATION_START_EVENT_SUBSCRIBER_ADDED":
        return (
          <Icon fontSize="small" mt={3}>
            group
          </Icon>
        );
      case "AUTOMATION_START_EVENT_FINISHED_SERIE":
        return (
          <Icon fontSize="small" mt={3}>
            content_copy
          </Icon>
        );
      case "AUTOMATION_START_EVENT_ENGAGED_BROADCAST":
        return (
          <Icon fontSize="small" mt={3}>
            podcasts
          </Icon>
        );

      case "AUTOMATION_START_EVENT_ENGAGED_CATALOG":
        return <StyleIcon fontSize="small" mt={3} />;
      case "AUTOMATION_START_EVENT_ENGAGED_ITEM":
        return <StyleIcon fontSize="small" mt={3} />;
      case "AUTOMATION_START_EVENT_REACHED_NOENGAGEMENT":
        return (
          <Icon fontSize="small" mt={3}>
            ads_click
          </Icon>
        );
      default:
        return "error";
    }
  };

  const getEvent = (type) => {
    switch (type) {
      case "AUTOMATION_START_EVENT_SUBSCRIBER_ADDED":
        return "Subscriber added";
      case "AUTOMATION_START_EVENT_FINISHED_SERIE":
        return "Finished a serie";
      case "AUTOMATION_START_EVENT_ENGAGED_BROADCAST":
        return "Engaged a broadcast";
      case "AUTOMATION_START_EVENT_ENGAGED_CATALOG":
        return "Engaged a catalog";
      case "AUTOMATION_START_EVENT_ENGAGED_ITEM":
        return "Engaged a catalog/item";
      case "AUTOMATION_START_EVENT_REACHED_NOENGAGEMENT":
        return "Reached no engagement limit";
      case "AUTOMATION_START_EVENT_TAG_ADDED":
        return "Subscriber gets tagged";
      default:
        return "error";
    }
  };

  const getLabel = (type) => {
    switch (type) {
      case "AUTOMATION_START_EVENT_SUBSCRIBER_ADDED":
        return "With Tag(s):";
      case "AUTOMATION_START_EVENT_FINISHED_SERIE":
        return "With serie:";
      case "AUTOMATION_START_EVENT_ENGAGED_BROADCAST":
        return "With broadcast:";
      case "AUTOMATION_START_EVENT_ENGAGED_CATALOG":
        return "With catalog:";
      case "AUTOMATION_START_EVENT_ENGAGED_ITEM":
        return "With item:";
      case "AUTOMATION_START_EVENT_TAG_ADDED":
        return "With Tag(s):";
      default:
        return "";
    }
  };

  const getData = (type) => {
    switch (type) {
      case "AUTOMATION_START_EVENT_SUBSCRIBER_ADDED":
        return (
          <MDBox>
            {node.data.aus_tag.split(",").map((tag) => {
              return <StyleChip variant="outlined" label={tag.trim()} size="small" />;
            })}
          </MDBox>
        );
      case "AUTOMATION_START_EVENT_TAG_ADDED":
        return (
          <MDBox>
            {node.data.aus_tag.split(",").map((tag) => {
              return <StyleChip variant="outlined" label={tag.trim()} size="small" />;
            })}
          </MDBox>
        );
      case "AUTOMATION_START_EVENT_FINISHED_SERIE":
        return (
          <MDBox>
            {node.data.aus_additionalData.split(",").map((tag) => {
              return <StyleChip variant="outlined" label={tag.trim()} size="small" />;
            })}
          </MDBox>
        );
      case "AUTOMATION_START_EVENT_ENGAGED_BROADCAST":
        return (
          <MDBox>
            {node.data.aus_additionalData.split(",").map((tag) => {
              return <StyleChip variant="outlined" label={tag.trim()} size="small" />;
            })}
          </MDBox>
        );
      case "AUTOMATION_START_EVENT_ENGAGED_CATALOG":
        return (
          <MDBox>
            {node.data.aus_additionalData.split(",").map((tag) => {
              return <StyleChip variant="outlined" label={tag.trim()} size="small" />;
            })}
          </MDBox>
        );
      case "AUTOMATION_START_EVENT_ENGAGED_ITEM":
        return (
          <MDBox>
            {node.data.aus_additionalData.split(",").map((tag) => {
              return <StyleChip variant="outlined" label={tag.trim()} size="small" />;
            })}
          </MDBox>
        );
      default:
        return "";
    }
  };

  return (
    <div
      style={{
        border: "1px solid #eee",
        padding: "5px",
        borderRadius: "5px",
        background: node.selected ? "#CCCCCC" : "#00CCFF22",
      }}
    >
      <MDBox display="flex">
        {getIcon(node.data.aus_type)}
        <MDBox display="flex" style={{ width: "100%" }}>
          <MDTypography variant="caption" fontWeight="medium" ml={1} style={{ width: "150px" }}>
            {getEvent(node.data.aus_type)}
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="right" style={{ width: "100%" }}>
          {node.selected && (
            <Tooltip title="delete event" placement="top">
              <Icon
                color="action"
                style={{ fontSize: "15px" }}
                onClick={(e) => node.data.onDelete(e, node)}
              >
                delete
              </Icon>
            </Tooltip>
          )}
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox display="flex">
        <MDTypography ml={1} variant="caption" fontWeight="medium">
          {getLabel(node.data.aus_type)}
        </MDTypography>
      </MDBox>
      {getData(node.data.aus_type)}

      <Handle type="source" position={Position.Bottom}>
        {node.selected && (
          <Tooltip title="Add a new action" placement="top">
            <Icon color="action" fontSize="small" onClick={onClick} style={{ marginLeft: -8 }}>
              add_circle
            </Icon>
          </Tooltip>
        )}
      </Handle>
    </div>
  );
}
