/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import { useState } from "react";
// @mui material components
import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { TableCell } from "@material-ui/core";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import EditIcon from "@mui/icons-material/Edit";
import client from "ApiClient";
import ConfirmationAlert from "components/ConfirmationAlert";
import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import CustomToolbarSelect from "./customToolbarSelect";
import Edit from "./edit";

// import Edit from "./edit";

/* eslint-disable */
export default function Table(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const handleOpenModal = () => setOpenModal(true);
  // const handleCloseModal = () => setOpenModal(false);

  const oldRender = TableCell.render;
  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };

  const options = {
    method: "GET",
    url: "segments",
  };

  const getData = async () => {
    setIsLoading(true);
    options.method = "GET";
    options.url = `segments`;
    await client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setItems(response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const handleEdit = () => {
    handleOpenModal();
  };

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, [props.groupAdded]);

  const handleDelete = () => {
    setIsLoading(true);
    options.method = "DELETE";
    options.url = `segments?seg_id=${selectedItem[0].seg_id}`;
    options.headers = {
      "content-type": "application/json",
    };
    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        getData();
        setOpenAlert(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const getSegmentSelected = (seg) => {
    const segmentProps = JSON.parse(seg.seg_criteria);

    const statuses =
      segmentProps.statuses.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Status:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.statuses
              .filter((element) => element.selected)
              .map((s) => s.status)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const educations =
      segmentProps.educations.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Education:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.educations
              .filter((element) => element.selected)
              .map((s) => s.education)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const children =
      segmentProps.children.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Children:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.children
              .filter((element) => element.selected)
              .map((s) => s.children)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const ethnicities =
      segmentProps.ethnicities.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Ethnicities:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.ethnicities
              .filter((element) => element.selected)
              .map((s) => s.ethnicity)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const genders =
      segmentProps.genders.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Genders:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.genders
              .filter((element) => element.selected)
              .map((s) => s.gender)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const age =
      segmentProps.ageRanges.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Age ranges:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.ageRanges
              .filter((element) => element.selected)
              .map((s) => s.age_range)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const employments =
      segmentProps.employments.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Employments:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.employments
              .filter((element) => element.selected)
              .map((s) => s.employment)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const broadcastsEngaged =
      segmentProps.broadcastsEngaged.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Broadcasts engaged:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.broadcastsEngaged
              .filter((element) => element.selected)
              .map((s) => s.name)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );

    const broadcastsNoEngaged =
      segmentProps.broadcastsNoEngaged.filter((element) => element.selected).length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Broadcasts no engaged:{" "}
          </MDTypography>
          <MDTypography variant="body">
            {segmentProps.broadcastsNoEngaged
              .filter((element) => element.selected)
              .map((s) => s.name)
              .join(", ")}
          </MDTypography>
        </div>
      ) : (
        ""
      );
    const tags =
      segmentProps.tags.length > 0 ? (
        <div>
          <MDTypography variant="body" fontWeight="medium">
            Tags:{" "}
          </MDTypography>
          <MDTypography variant="body">{segmentProps.tags.join(", ")}</MDTypography>
        </div>
      ) : (
        ""
      );

    return (
      <div>
        {statuses}
        {educations}
        {employments}
        {children}
        {ethnicities}
        {genders}
        {age}
        {broadcastsEngaged}
        {broadcastsNoEngaged}
        {tags}
      </div>
    );
  };

  const columns = [
    {
      label: "Friendly name",
      name: "seg_name",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Description",
      name: "seg_description",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
      },
    },
    {
      label: "Segmentation",
      name: "seg_criteria",
      options: {
        filterOptions: { fullWidth: true },
        filter: true,
        customBodyRenderLite: (dataIndex, rowIndex) => getSegmentSelected(items[dataIndex]),
      },
    },
    {
      label: " ",
      name: "seg_id",
      options: {
        filter: true,
        setCellHeaderProps: () => {
          return { align: "right" };
        },
        setCellProps: () => {
          return { align: "right" };
        },
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div>
              <IconButton
                onClick={() => {
                  setSelectedItem(items[dataIndex]);
                  setOpenEdit(true);
                }}
                sx={{ fontWeight: "bold" }}
                color="primary"
                aria-label="prompt"
              >
                <Tooltip id="button-report" title="Edit">
                  <EditIcon />
                </Tooltip>
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  const customToolbar = () => <CustomToolbarSelect onDelete={() => setOpenAlert(true)} />;

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: "You don't have any segment",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "segment/s selected",
      },
    },
    print: false,
    filter: false,
    viewColumns: false,
    download: false,
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: false,
    responsive: "simple",
    filterType: "dropdown",
    selectableRows: "single",
    fixedHeader: true,
    fixedSelectColumn: true,
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search",
    downloadOptions: { filename: "blogs.csv", separator: "," },
    onRowSelectionChange: (curRowSelected, allRowsSelected) => {
      let itemSelected = [];
      // emailsSelected.push(acconuntsData[curRowSelected[0].dataIndex]);
      allRowsSelected.map((row) => {
        itemSelected.push(items[row.dataIndex]);
      });
      setSelectedItem(itemSelected);
    },
    customToolbarSelect: customToolbar,
  };

  return (
    <div>
      <ConfirmationAlert
        openModal={openAlert}
        onClose={() => setOpenAlert(false)}
        onYes={handleDelete}
        yesText="Yes"
        title="Are you sure you want to delete this segment?"
      />
      <Edit
        openModal={openEdit}
        onClose={() => setOpenEdit(false)}
        segment={selectedItem}
        onDone={() => getData()}
      />
      <MDBox
        mx={2}
        mt={1}
        py={3}
        px={2}
        bgColor="badgeColors.primary"
        borderRadius="lg"
        variant="gradient"
      >
        <Grid container>
          <Grid item xs={12} md={10}>
            <MDTypography variant="h2">
              Segments
              {isLoading && (
                <CircularProgress
                  size={30}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              )}
            </MDTypography>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            style={{ width: "100%", textAlign: "right" }}
          >
            <MDBox justifyContent="right">
              <Tooltip title="Create a new segment">
                <MDButton variant="gradient" color="secondary" onClick={props.onAdd}>
                  create new
                </MDButton>
              </Tooltip>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox>
        <Grid container p={2}>
          <Grid item xs={12}>
            <div style={{ height: "100%", width: "100%" }}>
              <MUIDataTable data={items} columns={columns} options={tableOptions} />
            </div>
          </Grid>
        </Grid>
      </MDBox>
    </div>
  );
}
