import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import client from "ApiClient";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import { MenuItem, Select } from "@material-ui/core";

/* eslint-disable */
export default function Segments(props) {
  const [counting, setCounting] = useState(false);
  const [segmentsRows, setSegmentsRows] = useState(null);
  const [segmentProps, setSegmentProps] = useState(JSON.parse(props.segments));
  const [recipients, setRecipients] = useState(0);
  const [showRecipientsCount] = useState(props.showRecipientsCount);
  const [open, setOpen] = useState(props.isOpen ? props.isOpen : false);
  const [showButton, setShowButton] = useState(props.showButton ? props.showButton : true);
  const [openStatus, setOpenStatus] = useState(false);
  const [openEducation, setOpenEducation] = useState(false);
  const [openEmployment, setOpenEmployment] = useState(false);
  const [openChildren, setOpenChildren] = useState(false);
  const [openEthnicities, setOpenEthnicities] = useState(false);
  const [openGender, setOpenGender] = useState(false);
  const [openAge, setOpenAge] = useState(false);
  const [openBroadcastEngaged, setOpenBroadcastEngaged] = useState(false);
  const [openBroadcastNoEngaged, setOpenBroadcastNoEngaged] = useState(false);
  const [openTags, setOpenTags] = useState(false);
  const [tags, setTags] = useState(null);
  const [openExcludeTags, setOpenExcludeTags] = useState(false);
  const [excludeTags, setExcludeTags] = useState(null);
  const [suggestionsTag, setSuggestionsTag] = useState([]);
  const [items, setItems] = useState([]);
  const [load] = useState(props.load ?? false);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const filter = createFilterOptions();
  // const reactTags = useRef();

  const options = {
    method: "POST",
    url: `broadcasts/getSegmentsNoCounts`,
    headers: {
      "content-type": "application/json",
    },
  };

  const getSegment = async () => {
    options.method = "GET";
    options.url = `segments`;
    await client
      .request(options)
      .then((response) => {
        setItems(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTags = async () => {
    const options = {
      method: "GET",
      url: `broadcasts/getTags`,
      headers: {
        "content-type": "application/json",
      },
    };

    await client
      .request(options)
      .then((response) => {
        setSuggestionsTag([]);
        response.map((tag, index) => {
          setSuggestionsTag((prev) => [...prev, { id: index, name: tag }]);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSegments = async () => {
    const options = {
      method: "POST",
      url: `broadcasts/getSegmentsNoCounts`,
      headers: {
        "content-type": "application/json",
      },
    };

    if (props.filterSegments == null) {
      options.data = JSON.stringify({
        statuses: [],
        genders: [],
        children: [],
        ageRanges: [],
        educations: [],
        employments: [],
        ethnicities: [],
        broadcastsEngaged: [],
        broadcastsNoEngaged: [],
        tags: [],
        excludeTags: [],
      });
    } else {
      options.data = props.filterSegments;
    }

    await client
      .request(options)
      .then((response) => {
        setSegmentsRows(response);
        if (segmentProps) {
          setOpen(true);
          if (segmentProps.statuses.filter((element) => element.selected).length > 0) {
            setOpenStatus(true);
            let newSegments = [...response.statuses];
            newSegments.map((stat, index) => {
              if (
                segmentProps.statuses
                  .filter((element) => element.selected)
                  .find((fstat) => fstat.status == stat.status)
              ) {
                newSegments[index].selected = true;
              }
            });
            setSegmentsRows((prev) => ({
              ...prev,
              statuses: newSegments,
            }));
          }

          if (segmentProps.educations.filter((element) => element.selected).length > 0) {
            setOpenEducation(true);
            let newSegments = [...response.educations];
            newSegments.map((edu, index) => {
              if (
                segmentProps.educations
                  .filter((element) => element.selected)
                  .find((fedu) => fedu.education == edu.education)
              ) {
                newSegments[index].selected = true;
              }
            });
            setSegmentsRows((prev) => ({
              ...prev,
              educations: newSegments,
            }));
          }

          if (segmentProps.employments.filter((element) => element.selected).length > 0) {
            setOpenEmployment(true);
            let newSegments = [...response.employments];
            newSegments.map((emp, index) => {
              if (
                segmentProps.employments
                  .filter((element) => element.selected)
                  .find((femp) => femp.employment == emp.employment)
              ) {
                newSegments[index].selected = true;
              }
            });
            setSegmentsRows((prev) => ({
              ...prev,
              employments: newSegments,
            }));
          }

          if (segmentProps.children.filter((element) => element.selected).length > 0) {
            let newSegments = [...response.children];
            newSegments.map((stat, index) => {
              if (
                segmentProps.children
                  .filter((element) => element.selected)
                  .find((fstat) => fstat.children == stat.children)
              ) {
                newSegments[index].selected = true;
              }
            });
            setSegmentsRows((prev) => ({
              ...prev,
              children: newSegments,
            }));
            setOpenChildren(true);
          }

          if (segmentProps.ethnicities.filter((element) => element.selected).length > 0) {
            let newSegments = [...response.ethnicities];
            newSegments.map((stat, index) => {
              if (
                segmentProps.ethnicities
                  .filter((element) => element.selected)
                  .find((fstat) => fstat.ethnicity == stat.ethnicity)
              ) {
                newSegments[index].selected = true;
              }
            });
            setSegmentsRows((prev) => ({
              ...prev,
              ethnicities: newSegments,
            }));
            setOpenEthnicities(true);
          }

          if (segmentProps.genders.filter((element) => element.selected).length > 0) {
            let newSegments = [...response.genders];
            newSegments.map((stat, index) => {
              if (
                segmentProps.genders
                  .filter((element) => element.selected)
                  .find((fstat) => fstat.gender == stat.gender)
              ) {
                newSegments[index].selected = true;
              }
            });
            setSegmentsRows((prev) => ({
              ...prev,
              genders: newSegments,
            }));
            setOpenGender(true);
          }

          if (segmentProps.ageRanges.filter((element) => element.selected).length > 0) {
            let newSegments = [...response.ageRanges];
            newSegments.map((stat, index) => {
              if (
                segmentProps.ageRanges
                  .filter((element) => element.selected)
                  .find((fstat) => fstat.age_range == stat.age_range)
              ) {
                newSegments[index].selected = true;
              }
            });
            setSegmentsRows((prev) => ({
              ...prev,
              ageRanges: newSegments,
            }));
            setOpenAge(true);
          }

          if (segmentProps.broadcastsEngaged.filter((element) => element.selected).length > 0) {
            let newSegments = [...response.broadcastsEngaged];
            newSegments.map((stat, index) => {
              if (
                segmentProps.broadcastsEngaged
                  .filter((element) => element.selected)
                  .find((fstat) => fstat.id == stat.id)
              ) {
                newSegments[index].selected = true;
                stat.selected = true;
              }
            });
            setSegmentsRows((prev) => ({
              ...prev,
              broadcastsEngaged: newSegments,
            }));
            setOpenBroadcastEngaged(true);
          }

          if (segmentProps.broadcastsNoEngaged.filter((element) => element.selected).length > 0) {
            let newSegments = [...response.broadcastsNoEngaged];
            newSegments.map((stat, index) => {
              if (
                segmentProps.broadcastsNoEngaged
                  .filter((element) => element.selected)
                  .find((fstat) => fstat.id == stat.id)
              ) {
                newSegments[index].selected = true;
                stat.selected = true;
              }
            });
            setSegmentsRows((prev) => ({
              ...prev,
              broadcastsNoEngaged: newSegments,
            }));
            setOpenBroadcastNoEngaged(true);
          }

          if (segmentProps.tags.length > 0) {
            setTags([]);

            let newSegments = [...segmentProps.tags];
            segmentProps.tags.map((tag, index) => {
              setTags((prev) => [...prev, tag]);
            });
            setSegmentsRows((prev) => ({
              ...prev,
              tags: newSegments,
            }));
            setOpenTags(true);
          }

          if (segmentProps.excludeTags.length > 0) {
            setExcludeTags([]);

            let newSegments = [...segmentProps.excludeTags];
            segmentProps.excludeTags.map((tag, index) => {
              setExcludeTags((prev) => [...prev, tag]);
            });
            setSegmentsRows((prev) => ({
              ...prev,
              excludeTags: newSegments,
            }));
            setOpenExcludeTags(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getSegment();
    getTags();
    if (props.segments != null) getSegments();
    // getSubscribersSegments();
  }, []);

  useEffect(() => {
    if (segmentsRows != null) {
      setCounting(true);
      props.changeSegments(segmentsRows);
      options.method = "POST";
      options.url = `broadcasts/calculateRecipients`;
      options.data = segmentsRows;
      client
        .request(options)
        .then((response) => {
          setRecipients(response);
          setCounting(false);
        })
        .catch((error) => {
          console.log(error);
        });
      // console.log(segmentsRows);
    }
    //calculateRecipients();
  }, [segmentsRows]);

  useEffect(() => {
    setOpenStatus(false);
    setOpenEducation(false);
    setOpenEmployment(false);
    setOpenChildren(false);
    setOpenEthnicities(false);
    setOpenGender(false);
    setOpenAge(false);
    setOpenBroadcastEngaged(false);
    setOpenBroadcastNoEngaged(false);
    setOpenTags(false);
    setOpenExcludeTags(false);
    setExcludeTags([]);
    setTags([]);
    getSegments();
  }, [segmentProps]);

  useEffect(() => {
    setSegmentProps(JSON.parse(props.segments));
  }, [props.segments]);

  useEffect(() => {
    setShowButton(props.showButton);
    if (!props.showButton) setOpen(true);
  }, [props]);

  useEffect(() => {
    if (tags != null && segmentsRows != null) {
      setSegmentsRows((prev) => ({
        ...prev,
        tags: tags,
      }));
    }
  }, [tags]);

  useEffect(() => {
    if (excludeTags != null && segmentsRows != null) {
      setSegmentsRows((prev) => ({
        ...prev,
        excludeTags: excludeTags,
      }));
    }
  }, [excludeTags]);

  const changeCheckFilter = (segment, index, selectedSegment) => {
    switch (segment) {
      case "statuses":
        var newSegments = [...segmentsRows.statuses];
        newSegments[index].selected = selectedSegment;
        setSegmentsRows((prev) => ({
          ...prev,
          statuses: newSegments,
        }));
        break;
      case "genders":
        var newSegments = [...segmentsRows.genders];
        newSegments[index].selected = selectedSegment;

        setSegmentsRows((prev) => ({
          ...prev,
          genders: newSegments,
        }));
        break;
      case "ethnicities":
        var newSegments = [...segmentsRows.ethnicities];
        newSegments[index].selected = selectedSegment;

        setSegmentsRows((prev) => ({
          ...prev,
          ethnicities: newSegments,
        }));
        break;
      case "employments":
        var newSegments = [...segmentsRows.employments];
        newSegments[index].selected = selectedSegment;

        setSegmentsRows((prev) => ({
          ...prev,
          employments: newSegments,
        }));
        break;
      case "educations":
        var newSegments = [...segmentsRows.educations];
        newSegments[index].selected = selectedSegment;

        setSegmentsRows((prev) => ({
          ...prev,
          educations: newSegments,
        }));
        break;
      case "children":
        var newSegments = [...segmentsRows.children];
        newSegments[index].selected = selectedSegment;

        setSegmentsRows((prev) => ({
          ...prev,
          children: newSegments,
        }));
        break;
      case "ageRanges":
        var newSegments = [...segmentsRows.ageRanges];
        newSegments[index].selected = selectedSegment;

        setSegmentsRows((prev) => ({
          ...prev,
          ageRanges: newSegments,
        }));
        break;
      case "broadcastsEngaged":
        var newSegments = [...segmentsRows.broadcastsEngaged];
        newSegments[index].selected = selectedSegment;

        setSegmentsRows((prev) => ({
          ...prev,
          broadcastsEngaged: newSegments,
        }));
        break;
      case "broadcastsNoEngaged":
        var newSegments = [...segmentsRows.broadcastsNoEngaged];
        newSegments[index].selected = selectedSegment;

        setSegmentsRows((prev) => ({
          ...prev,
          broadcastsNoEngaged: newSegments,
        }));
        break;
    }

    //  calculateRecipients();
  };

  const onChangeLoad = (e) => {
    setSegmentProps(JSON.parse(e.target.value));
    setSelectedSegment(e.target.value);
  };

  return (
    <Grid>
      {showButton && (
        <MDButton onClick={() => setOpen(!open)} fullWidth variant="gradient" color="primary">
          Segments {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </MDButton>
      )}
      {open && (
        <MDBox mb={2}>
          {load && items.length > 0 && (
            <MDBox pb={2} display="flex" justifyContent="left">
              <MDTypography variant="subtitle2" fontWeight="medium" ml={1} mr={2} mt={1}>
                Load a segment
              </MDTypography>
              <Select onChange={(e) => onChangeLoad(e)} value={selectedSegment}>
                {items.map((item) => (
                  <MenuItem key={item.seg_id} value={item.seg_criteria}>
                    {item.seg_name}
                  </MenuItem>
                ))}
              </Select>
            </MDBox>
          )}
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              {segmentsRows != null && (
                <TableBody>
                  {segmentsRows.statuses != undefined && segmentsRows.statuses.length > 1 && (
                    <>
                      <TableRow
                        sx={{
                          "& > *": { borderBottom: "unset" },
                        }}
                        style={{ backgroundColor: "#f0f2f5" }}
                      >
                        <TableCell component="th" scope="row">
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenStatus(!openStatus)}
                          >
                            {openStatus ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </TableCell>
                        <TableCell width="100%" component="th" scope="row">
                          Statuses
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                          <Collapse in={openStatus} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                              <Table size="small" aria-label="purchases">
                                <TableBody>
                                  {segmentsRows.statuses.map((status, index) => (
                                    <TableRow
                                      key={status.status}
                                      sx={{
                                        "& > *": { borderBottom: "unset" },
                                      }}
                                      style={{ backgroundColor: "#FFFFFF" }}
                                    >
                                      <TableCell width={5}>
                                        <Checkbox
                                          checked={status.selected}
                                          disabled={!status.editable}
                                          onChange={() =>
                                            changeCheckFilter("statuses", index, !status.selected)
                                          }
                                        ></Checkbox>
                                      </TableCell>
                                      <TableCell width="100%">{status.status}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                  {segmentsRows.educations != undefined &&
                    segmentsRows.educations.length > 1 &&
                    segmentsRows.educations[0].education != "Unknown" && (
                      <>
                        <TableRow
                          sx={{ "& > *": { borderBottom: "unset" } }}
                          style={{ backgroundColor: "#f0f2f5" }}
                        >
                          <TableCell component="th" scope="row">
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => setOpenEducation(!openEducation)}
                            >
                              {openEducation ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </TableCell>
                          <TableCell width="100%" component="th" scope="row">
                            Educations
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={openEducation} timeout="auto" unmountOnExit>
                              <Box sx={{ margin: 1 }}>
                                <Table size="small" aria-label="purchases">
                                  <TableBody>
                                    {segmentsRows.educations.map((educations, index) => (
                                      <TableRow
                                        key={educations.education}
                                        sx={{
                                          "& > *": { borderBottom: "unset" },
                                        }}
                                        style={{ backgroundColor: "#FFFFFF" }}
                                      >
                                        <TableCell width={10}>
                                          <Checkbox
                                            width={5}
                                            checked={educations.selected}
                                            disabled={!educations.editable}
                                            onChange={() =>
                                              changeCheckFilter(
                                                "educations",
                                                index,
                                                !educations.selected
                                              )
                                            }
                                          ></Checkbox>
                                        </TableCell>
                                        <TableCell width="100%">{educations.education}</TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  {segmentsRows.employments != undefined &&
                    segmentsRows.employments.length > 1 &&
                    segmentsRows.employments[0].employment != "Unknown" && (
                      <>
                        <TableRow
                          sx={{ "& > *": { borderBottom: "unset" } }}
                          style={{ backgroundColor: "#f0f2f5" }}
                        >
                          <TableCell component="th" scope="row">
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => setOpenEmployment(!openEmployment)}
                            >
                              {openEmployment ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            Employments
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={openEmployment} timeout="auto" unmountOnExit>
                              <Box sx={{ margin: 1 }}>
                                <Table size="small" aria-label="purchases">
                                  <TableBody>
                                    {segmentsRows.employments.map((employments, index) => (
                                      <TableRow
                                        key={employments.employment}
                                        sx={{
                                          "& > *": { borderBottom: "unset" },
                                        }}
                                        style={{ backgroundColor: "#FFFFFF" }}
                                      >
                                        <TableCell width={5}>
                                          <Checkbox
                                            checked={employments.selected}
                                            disabled={!employments.editable}
                                            onChange={() =>
                                              changeCheckFilter(
                                                "employments",
                                                index,
                                                !employments.selected
                                              )
                                            }
                                          ></Checkbox>
                                        </TableCell>
                                        <TableCell width="100%">{employments.employment}</TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  {segmentsRows.children != undefined &&
                    segmentsRows.children.length > 1 &&
                    segmentsRows.children[0].children != "Unknown" && (
                      <>
                        <TableRow
                          sx={{ "& > *": { borderBottom: "unset" } }}
                          style={{ backgroundColor: "#f0f2f5" }}
                        >
                          <TableCell component="th" scope="row">
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => setOpenChildren(!openChildren)}
                            >
                              {openChildren ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            Children
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={openChildren} timeout="auto" unmountOnExit>
                              <Box sx={{ margin: 1 }}>
                                <Table size="small" aria-label="purchases">
                                  <TableBody>
                                    {segmentsRows.children.map((children, index) => (
                                      <TableRow
                                        key={children.children}
                                        sx={{
                                          "& > *": { borderBottom: "unset" },
                                        }}
                                        style={{ backgroundColor: "#FFFFFF" }}
                                      >
                                        <TableCell width={5}>
                                          <Checkbox
                                            checked={children.selected}
                                            disabled={!children.editable}
                                            onChange={() =>
                                              changeCheckFilter(
                                                "children",
                                                index,
                                                !children.selected
                                              )
                                            }
                                          ></Checkbox>
                                        </TableCell>
                                        <TableCell width="100%">{children.children}</TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  {segmentsRows.ethnicities != undefined &&
                    segmentsRows.ethnicities.length > 1 &&
                    segmentsRows.ethnicities[0].ethnicity != "Unknown" && (
                      <>
                        <TableRow
                          sx={{ "& > *": { borderBottom: "unset" } }}
                          style={{ backgroundColor: "#f0f2f5" }}
                        >
                          <TableCell component="th" scope="row">
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => setOpenEthnicities(!openEthnicities)}
                            >
                              {openEthnicities ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            Ethnicities
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={openEthnicities} timeout="auto" unmountOnExit>
                              <Box sx={{ margin: 1 }}>
                                <Table size="small" aria-label="purchases">
                                  <TableBody>
                                    {segmentsRows.ethnicities.map((ethnicities, index) => (
                                      <TableRow
                                        key={ethnicities.ethnicity}
                                        sx={{
                                          "& > *": { borderBottom: "unset" },
                                        }}
                                        style={{ backgroundColor: "#FFFFFF" }}
                                      >
                                        <TableCell width={5}>
                                          <Checkbox
                                            checked={ethnicities.selected}
                                            disabled={!ethnicities.editable}
                                            onChange={() =>
                                              changeCheckFilter(
                                                "ethnicities",
                                                index,
                                                !ethnicities.selected
                                              )
                                            }
                                          ></Checkbox>
                                        </TableCell>
                                        <TableCell width="100%">{ethnicities.ethnicity}</TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  {segmentsRows.genders != undefined &&
                    segmentsRows.genders.length > 1 &&
                    segmentsRows.genders[0].gender != "Unknown" && (
                      <>
                        <TableRow
                          sx={{ "& > *": { borderBottom: "unset" } }}
                          style={{ backgroundColor: "#f0f2f5" }}
                        >
                          <TableCell component="th" scope="row">
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => setOpenGender(!openGender)}
                            >
                              {openGender ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            Genders
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={openGender} timeout="auto" unmountOnExit>
                              <Box sx={{ margin: 1 }}>
                                <Table size="small" aria-label="purchases">
                                  <TableBody>
                                    {segmentsRows.genders.map((genders, index) => (
                                      <TableRow
                                        key={genders.gender}
                                        sx={{
                                          "& > *": { borderBottom: "unset" },
                                        }}
                                        style={{ backgroundColor: "#FFFFFF" }}
                                      >
                                        <TableCell>
                                          <Checkbox
                                            width={5}
                                            checked={genders.selected}
                                            disabled={!genders.editable}
                                            onChange={() =>
                                              changeCheckFilter("genders", index, !genders.selected)
                                            }
                                          ></Checkbox>
                                        </TableCell>
                                        <TableCell width="100%">{genders.gender}</TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    )}

                  {segmentsRows.ageRanges != undefined &&
                    segmentsRows.ageRanges.length > 1 &&
                    segmentsRows.ageRanges[0].ageRange != "Unknown" && (
                      <>
                        <TableRow
                          sx={{ "& > *": { borderBottom: "unset" } }}
                          style={{ backgroundColor: "#f0f2f5" }}
                        >
                          <TableCell component="th" scope="row">
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => setOpenAge(!openAge)}
                            >
                              {openAge ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            Age Ranges
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={openAge} timeout="auto" unmountOnExit>
                              <Box sx={{ margin: 1 }}>
                                <Table size="small" aria-label="purchases">
                                  <TableBody>
                                    {segmentsRows.ageRanges.map((ageRanges, index) => (
                                      <TableRow
                                        key={ageRanges.age_range}
                                        sx={{
                                          "& > *": { borderBottom: "unset" },
                                        }}
                                        style={{ backgroundColor: "#FFFFFF" }}
                                      >
                                        <TableCell>
                                          <Checkbox
                                            width={5}
                                            checked={ageRanges.selected}
                                            disabled={!ageRanges.editable}
                                            onChange={() =>
                                              changeCheckFilter(
                                                "ageRanges",
                                                index,
                                                !ageRanges.selected
                                              )
                                            }
                                          ></Checkbox>
                                        </TableCell>
                                        <TableCell width="100%">{ageRanges.age_range}</TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    )}

                  {segmentsRows.broadcastsEngaged != undefined &&
                    segmentsRows.broadcastsEngaged.length > 0 && (
                      <>
                        <TableRow
                          sx={{ "& > *": { borderBottom: "unset" } }}
                          style={{ backgroundColor: "#f0f2f5" }}
                        >
                          <TableCell component="th" scope="row">
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => setOpenBroadcastEngaged(!openBroadcastEngaged)}
                            >
                              {openBroadcastEngaged ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell width="100%" component="th" scope="row">
                            Previous Broadcasts Opened
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={openBroadcastEngaged} timeout="auto" unmountOnExit>
                              <Box sx={{ margin: 1 }}>
                                <Table size="small" aria-label="purchases">
                                  <TableBody>
                                    {segmentsRows.broadcastsEngaged.map(
                                      (broadcastsEngaged, index) => (
                                        <TableRow
                                          key={"BE" + broadcastsEngaged.id + broadcastsEngaged.name}
                                          sx={{
                                            "& > *": { borderBottom: "unset" },
                                          }}
                                          style={{ backgroundColor: "#FFFFFF" }}
                                        >
                                          <TableCell>
                                            <Checkbox
                                              width={5}
                                              checked={broadcastsEngaged.selected}
                                              disabled={!broadcastsEngaged.editable}
                                              onChange={() =>
                                                changeCheckFilter(
                                                  "broadcastsEngaged",
                                                  index,
                                                  !broadcastsEngaged.selected
                                                )
                                              }
                                            ></Checkbox>
                                          </TableCell>
                                          <TableCell width="100%">
                                            {broadcastsEngaged.name}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    )}

                  {segmentsRows.broadcastsNoEngaged != undefined &&
                    segmentsRows.broadcastsNoEngaged.length > 0 && (
                      <>
                        <TableRow
                          sx={{ "& > *": { borderBottom: "unset" } }}
                          style={{ backgroundColor: "#f0f2f5" }}
                        >
                          <TableCell component="th" scope="row">
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => setOpenBroadcastNoEngaged(!openBroadcastNoEngaged)}
                            >
                              {openBroadcastNoEngaged ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell width="100%" component="th" scope="row">
                            Previous Broadcasts Unopened
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={openBroadcastNoEngaged} timeout="auto" unmountOnExit>
                              <Box sx={{ margin: 1 }}>
                                <Table size="small" aria-label="purchases">
                                  <TableBody>
                                    {segmentsRows.broadcastsNoEngaged.map(
                                      (broadcastsNoEngaged, index) => (
                                        <TableRow
                                          key={
                                            "BNE" +
                                            broadcastsNoEngaged.id +
                                            broadcastsNoEngaged.name
                                          }
                                          sx={{
                                            "& > *": { borderBottom: "unset" },
                                          }}
                                          style={{ backgroundColor: "#FFFFFF" }}
                                        >
                                          <TableCell>
                                            <Checkbox
                                              width={5}
                                              checked={broadcastsNoEngaged.selected}
                                              disabled={!broadcastsNoEngaged.editable}
                                              onChange={() =>
                                                changeCheckFilter(
                                                  "broadcastsNoEngaged",
                                                  index,
                                                  !broadcastsNoEngaged.selected
                                                )
                                              }
                                            ></Checkbox>
                                          </TableCell>
                                          <TableCell width="100%">
                                            {broadcastsNoEngaged.name}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    )}

                  <>
                    <TableRow
                      sx={{ "& > *": { borderBottom: "unset" } }}
                      style={{ backgroundColor: "#f0f2f5" }}
                    >
                      <TableCell component="th" scope="row">
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => setOpenTags(!openTags)}
                        >
                          {openTags ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell width="100%" component="th" scope="row">
                        Tags
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        width="100%"
                        component="th"
                        scope="row"
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <Collapse in={openTags} timeout="auto" unmountOnExit>
                          <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                              <TableBody>
                                <TableRow
                                  sx={{
                                    "& > *": { borderBottom: "unset" },
                                  }}
                                  style={{ backgroundColor: "#FFFFFF" }}
                                >
                                  <TableCell width="100%" component="th" scope="row">
                                    <Autocomplete
                                      value={tags}
                                      onChange={(event, newValue) => {
                                        setTags(
                                          newValue.map((tag) =>
                                            tag.indexOf('Create new tag "') === -1
                                              ? tag
                                              : tag.substring(16).slice(0, -1)
                                          )
                                        );
                                      }}
                                      multiple
                                      id="tags-filled"
                                      options={suggestionsTag.map((option) => option.name)}
                                      renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                          <Chip
                                            variant="outlined"
                                            label={option}
                                            {...getTagProps({ index })}
                                          />
                                        ))
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Type to search or add a new tag and press enter"
                                        />
                                      )}
                                    />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>

                  <>
                    <TableRow
                      sx={{ "& > *": { borderBottom: "unset" } }}
                      style={{ backgroundColor: "#f0f2f5" }}
                    >
                      <TableCell component="th" scope="row">
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => setOpenExcludeTags(!openExcludeTags)}
                        >
                          {openExcludeTags ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </TableCell>
                      <TableCell width="100%" component="th" scope="row">
                        Exclude Tags
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        width="100%"
                        component="th"
                        scope="row"
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <Collapse in={openExcludeTags} timeout="auto" unmountOnExit>
                          <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                              <TableBody>
                                <TableRow
                                  sx={{
                                    "& > *": { borderBottom: "unset" },
                                  }}
                                  style={{ backgroundColor: "#FFFFFF" }}
                                >
                                  <TableCell width="100%" component="th" scope="row">
                                    <Autocomplete
                                      value={excludeTags}
                                      onChange={(event, newValue) => {
                                        setExcludeTags(
                                          newValue.map((tag) =>
                                            tag.indexOf('Create new tag "') === -1
                                              ? tag
                                              : tag.substring(16).slice(0, -1)
                                          )
                                        );
                                      }}
                                      multiple
                                      id="tags-filled"
                                      options={suggestionsTag.map((option) => option.name)}
                                      renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                          <Chip
                                            variant="outlined"
                                            label={option}
                                            {...getTagProps({ index })}
                                          />
                                        ))
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Type to search or add a new tag and press enter"
                                        />
                                      )}
                                    />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {showRecipientsCount && (
            <MDBox mb={2}>
              <MDTypography>
                Recipients count{" "}
                {counting ? (
                  <CircularProgress
                    size={24}
                    style={{ marginLeft: 15, position: "relative", top: 4 }}
                  />
                ) : (
                  recipients
                )}
              </MDTypography>
            </MDBox>
          )}
        </MDBox>
      )}
    </Grid>
  );
}

/*
 <TableCell align="right" component="th" scope="row">
                        {segmentsRows.broadcastsEngaged
                          .map((cnt) => cnt.count)
                          .reduce((a, b) => a + b)}
                      </TableCell>
                      
                      <TableCell align="right" component="th" scope="row">
                        {Math.max(...segmentsRows.broadcastsNoEngaged.map((cnt) => cnt.count))}
                      </TableCell>

                        <TableCell align="right">
                                        {broadcastsNoEngaged.count}
                                      </TableCell>

                      */
