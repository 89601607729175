/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
import theme from "assets/theme";
// react-router-dom components
import { Link, useNavigate, useSearchParams } from "react-router-dom";
// @mui material components

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
// Images
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import md5 from "md5";
import client from "ApiClient";
import { IconButton, InputAdornment, Tooltip } from "@material-ui/core";
import { Grid } from "@mui/material";
import { useMaterialUIController } from "context";
import MSLeftPanel from "components/MSLeftPanel/MSLeftPanel";

/*eslint-disable*/
function Basic() {
  const [errorSB, setErrorSB] = useState(false);
  const navigate = useNavigate();
  const closeErrorSB = () => setErrorSB(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passVisible, setPassVisible] = useState(false);
  const [searchParams] = useSearchParams();
  const [controller, dispatch] = useMaterialUIController();

  const handleLogin = () => {
    const options = {
      method: "GET",
      url: `users/login`,
      headers: {
        "content-type": "application/json",
      },
    };

    options.params = {
      email: email,
      password: window.btoa(password),
    };

    client
      .request(options)
      .then((response) => {
        if (response == "unauthorized") setErrorSB(true);
        else if (response.toString().indexOf("http") !== -1) {
          window.location.href = response;
        } else {
          switch (process.env.REACT_APP_MAIN_DOMAIN) {
            case "localhost":
              localStorage.setItem("unlayerID", 229090);
              localStorage.setItem("plName", "SendPad");
              break;
            case "sendpaddev.com":
              localStorage.setItem("unlayerID", 229090);
              localStorage.setItem("plName", "SendPad");
              break;
            case "sendpad.com":
              localStorage.setItem("unlayerID", 229090);
              localStorage.setItem("plName", "SendPad");
              break;
            case "mailsense.ai":
              localStorage.setItem("unlayerID", 205839);
              localStorage.setItem("plName", "mailSense");
              break;
            default:
              localStorage.setItem("unlayerID", 229090);
              localStorage.setItem("plName", "SendPad");
              break;
          }
          console.log(response);
          localStorage.setItem("AuthorizationToken", JSON.stringify(response.user_token));
          localStorage.setItem("userId", response.user_id);
          localStorage.setItem("userName", response.user_name);
          localStorage.setItem("isFree", response.user_free);

          getRepliesCount();
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
        setErrorSB(true);
      });
  };

  const getRepliesCount = () => {
    const options = {
      method: "GET",
      url: `replies/repliesCount`,
      headers: {
        "content-type": "application/json",
      },
    };

    client
      .request(options)
      .then((response) => {
        localStorage.setItem("repliesCount", response);
        navigate("/reports"); // window.location.href = "/"; // navigate("/blogArticles");
        window.location.reload();
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  useEffect(() => {
    /*
    setTransparentSidenav(dispatch, true);
    setWhiteSidenav(dispatch, true);
    setFixedNavbar(dispatch, false);
    setDarkMode(dispatch, false);
    setSidenavColor(dispatch, "primary");
    */
    if (searchParams.get("email") !== null) {
      setEmail(searchParams.get("email"));
    }
  }, []);

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Login failed"
      content="Your credentails are wrong"
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      backgroundcolor={"error"}
    />
  );

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        xl={4}
        style={{ height: "100vh" }}
        sx={{
          //You can copy the code below in your theme
          background: theme.palette.background.default,
          "& .MuiPaper-root": {
            background: theme.palette.background.default,
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent", // Try to remove this to see the result
          },
        }}
      >
        <MSLeftPanel type="sign-in" />
      </Grid>
      <Grid
        item
        xs={12}
        xl={8}
        sx={{
          //You can copy the code below in your theme
          background: "#FFFFFF",
          "& .MuiPaper-root": {
            background: "#FFFFF",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent", // Try to remove this to see the result
          },
        }}
      >
        <MDBox mt={10} display="flex" justifyContent="center">
          <MDBox component="form" role="form" style={{ width: "40%" }}>
            <MDTypography variant="h2" fontWeight="medium" mb={2}>
              Sign in
            </MDTypography>

            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                required
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                required
                type={passVisible ? "text" : "password"}
                label="Password"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setPassVisible(!passVisible)}
                        color="secondary"
                        aria-label="prompt"
                      >
                        {!passVisible ? (
                          <Tooltip title="View password" color="text">
                            <VisibilityIcon />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Hide password" color="text">
                            <VisibilityOffIcon />
                          </Tooltip>
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </MDBox>

            <MDBox display="flex" justifyContent="right">
              <MDTypography
                component="a"
                href="/#/forgot"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
                sx={{ cursor: "pointer", userSelect: "none" }}
              >
                Forgot password?
              </MDTypography>
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="primary" fullWidth onClick={handleLogin}>
                Sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Dont have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Register now
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
        {renderErrorSB}
      </Grid>
    </Grid>
  );
}

export default Basic;
