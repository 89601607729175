import { createRoot } from "react-dom/client";
import React from "react";
import { HashRouter } from "react-router-dom";
// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import "./App.css";
import App from "./App";

// import registerServiceWorker from "./registerServiceWorker";
import { FeatureFlagsProvider } from "./context/FeatureFlags";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
/*
root.render(
  <BrowserRouter basename="/">
    <MaterialUIControllerProvider>
      <App />
    </MaterialUIControllerProvider>
  </BrowserRouter>
);

registerServiceWorker();
*/

root.render(
  <HashRouter>
    <MaterialUIControllerProvider>
      <FeatureFlagsProvider>
        <App />
      </FeatureFlagsProvider>
    </MaterialUIControllerProvider>
  </HashRouter>
);
